<template>
  <v-row class="mx-3">
    <!-- <pre>{{ vacancies }}</pre> -->
    <v-simple-table dense fixed-header style="width: 100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("vacancies") }}
            </th>
            <th class="text-left">
              {{ $t("tests") }}
            </th>
            <th class="text-left">
              {{ $t("score") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in vacancies" :key="item.id">
            <td>{{ item.title }}</td>
            <td>
              <v-chip
                v-on="on"
                small
                color="primary"
                class="text-center"
                dark
                label
                @click="reviewTest(getTests(item))"
              >
                <v-icon small class="">mdi-clipboard-text-search</v-icon>
              </v-chip>
            </td>
            <td>
              <v-progress-linear
                v-if="getTests(item).length > 0"
                :value="calculePontuation(getTests(item))"
                :color="setColor(calculePontuation(getTests(item)))"
                height="16"
                readonly
              >
                <span class="text--white">
                  {{ calculePontuation(getTests(item)) }}%</span
                >
              </v-progress-linear>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <review-test
      :dialog="reviewDialog"
      :tests="tests"
      @close="reviewDialog = false"
      @fetch="true"
    />
  </v-row>
</template>
<script>
import moment from "moment";
import ReviewTest from "../../ReviewTestList.vue";
export default {
  props: {
    vacancies: Array,
    vacancyId: String,
    candidateId: String,
  },
  components: {
    ReviewTest,
  },
  data() {
    return {
      tests: [],
      reviewDialog: false,
    };
  },
  methods: {
    reviewTest(tests) {
      this.tests = this.removeDuplicates(tests);
      this.reviewDialog = true;
    },
    removeDuplicates(array) {
      return array.filter(
        (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
      );
    },
    calculePontuation(tests) {
      let total = tests.length;
      let totaScoreObsrvation = 0;
      let result =
        tests.reduce((accumulator, item) => {
          if (!accumulator) {
            accumulator = 0;
          }
          if (!item.result) {
            item.result = 0;
          }
          if (item.type == "psychological" || item.type == "technician") {
            console.log(item.type, item.questions);
            let cal = item.questions.find((q) => q.openQuestion === true);
            if (cal) {
              // console.log(cal);
              if (!item.stage) {
                totaScoreObsrvation = cal.observations.length
                  ? cal.observations
                      .map((obs) => {
                        return obs.result;
                      })
                      .map(Number)
                      .reduce((acc, curr) => acc + curr, 0)
                  : 0;
              } else {
                item.questions.forEach((q) => {
                  if (item.questions.length) {
                    // if (q.openQuestion === true) {
                    q.observations.forEach((element) => {
                      totaScoreObsrvation += parseFloat(element.result);
                    });
                    // }
                  }
                });
              }

              console.log(
                parseFloat(totaScoreObsrvation),
                parseFloat(item.result),
                parseFloat(accumulator)
              );

              return (
                parseFloat(accumulator) +
                parseFloat(item.result) +
                parseFloat(totaScoreObsrvation)
              );
            } else {
              // total = total - 1;
              console.log("tecn", parseFloat(accumulator));
              return parseFloat(accumulator) + parseFloat(item.result);
            }
          } else if (item.result) {
            return accumulator + item.result;
          } else if (!this.days_(item)) {
            return accumulator + 0;
          } else {
            total = total - 1;
            return accumulator + 0;
          }
        }, 0) / total;
      console.log(result);
      return result >= 0 ? Number(result.toFixed(2)) : 0;
    },
    days_(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
    getTests(item) {
      if (item.candidacies.length) {
        return item.candidacies
          .find((c) => c.candidate === this.candidateId)
          .tests.filter((t) => t.candidate === this.candidateId);
      }
      return [];
    },
    setColor(punctuation) {
      if (punctuation < 50) {
        return "red";
      } else if (punctuation < 70) {
        return "#FFC107";
      } else {
        return "green";
      }
    },
  },
};
</script>
